import React from "react";
import { Button } from "../ui/Button";
import { Img } from "../ui/Img";
import { Input } from "../ui/Input";
import { Separator } from "../ui/Separator";
import { Text } from "../ui/Text";

interface Props {
  className?: string;
}

export default function FooterPage({ className = "" }: Props) {
  const menuItems = [
    { title: "About", link: "/about" },
    { title: "Pricing", link: "/pricing" },
    { title: "Pricing Single", link: "/pricing-single" },
    { title: "Contact", link: "/contact" },
    { title: "Team", link: "/team" },
    { title: "Team Details", link: "/team-details" },
    { title: "Home", link: "/" },
  ];

  const cmsPages = [
    { title: "Blog Posts", link: "/blog" },
    { title: "Blog Single", link: "/blog-single" },
    { title: "Service", link: "/service" },
    { title: "Service Details", link: "/service-details" },
    { title: "Case Studies", link: "/case-studies" },
    { title: "Case Studies Details", link: "/case-studies-details" },
    { title: "FAQ", link: "/faq" },
  ];

  const utilityPages = [
    { title: "Style Guide", link: "/style-guide" },
    { title: "Changelog", link: "/changelog" },
    { title: "License", link: "/license" },
    { title: "Term and policy", link: "/protected" },
    { title: "404 Not Found", link: "/404" },
  ];

  return (
    <div
      className={`${className} flex flex-col gap-8 py-8 sm:py-6 bg-blue_gray-900`}
    >
      <div className="mx-auto w-full max-w-[1440px] flex flex-col gap-8 px-5 items-start">
        {/* Top Section: Logo and Contact Button */}
        <div className="flex justify-between w-full items-center gap-6">
          <Img
            src="/Images/meta-square.png"
            alt="Footer Logo"
            className="h-[66px] w-auto object-contain"
          />
          <Button className="px-6 py-2 bg-blue-500 text-white rounded-md font-roboto">
            Contact
          </Button>
        </div>

        <Separator orientation="horizontal" className="bg-gray-700 my-4" />

        {/* Newsletter and Footer Links Container */}
        <div className="flex flex-col lg:flex-row lg:justify-between w-full gap-10">
          {" "}
          {/* Adjusted gap from 8 to 4 */}
          {/* Newsletter Section */}
          <div className="flex flex-col items-start gap-4 w-full lg:w-[30%] text-left">
            <Text className="text-[24px] font-roboto text-white">
              Newsletter
            </Text>
            <Text className="text-[14px] leading-[22px] text-gray-300">
              Stay updated with our latest news and offers. Subscribe to our
              newsletter.
            </Text>
            <div className="flex w-full gap-2">
              <Input
                placeholder="Email Address"
                className="flex-grow rounded-md px-4 py-2 text-sm border border-gray-300 focus:border-blue-500 border-solid"
              />
              <Button className="px-4 py-2 bg-blue-500 text-white rounded-md">
                Submit
              </Button>
            </div>
          </div>
          {/* Links Section */}
          <div className="flex flex-wrap justify-start lg:justify-between gap-6 w-full">
            {/* Pages Section */}
            <div className="flex flex-col items-start gap-4">
              <Text className="text-lg font-roboto text-blue_gray-400">
                Pages
              </Text>
              <ul className="space-y-2 text-gray-300">
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <a href={item.link} className="hover:text-white">
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* CMS Pages Section */}
            <div className="flex flex-col items-start gap-4">
              <Text className="text-lg font-roboto text-blue_gray-400">
                CMS Pages
              </Text>
              <ul className="space-y-2 text-gray-300">
                {cmsPages.map((item, index) => (
                  <li key={index}>
                    <a href={item.link} className="hover:text-white">
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Utility Pages Section */}
            <div className="flex flex-col items-start gap-4">
              <Text className="text-lg font-roboto text-blue_gray-400">
                Utility Pages
              </Text>
              <ul className="space-y-2 text-gray-300">
                {utilityPages.map((item, index) => (
                  <li key={index}>
                    <a href={item.link} className="hover:text-white">
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Separator orientation="horizontal" className="bg-gray-700 my-4" />

      {/* Footer Bottom */}
      <div className="text-center text-sm text-gray-400 mt-4">
        Powered and Designed by Meta Square
      </div>
    </div>
  );
}
