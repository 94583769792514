import React, { useState, useEffect } from "react";
import { Globe, Menu, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from "../ui/NavigationMenu";
import { Button } from "../ui/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  className?: string;
}

const menuItems = [
  "About",
  "Products",
  "Courses",
  "Blog",
  "Summer Camp",
  "Contact Us",
];

export default function Header({ className }: Props = { className: "" }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  // Effect to detect scroll and change the background color
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleLanguageMenu = () => setIsLanguageOpen(!isLanguageOpen);
  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language);
    setIsLanguageOpen(false);
    setIsMenuOpen(false); // Close the menu if it's open on mobile
  };
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 py-5 ${
        isScrolled || isHovered
          ? "bg-white-a700 bg-opacity-90"
          : "bg-transparent"
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="container mx-auto flex items-center justify-between gap-4">
        <img
          onClick={handleLogoClick}
          src="/Images/meta-square.png"
          alt="Meta Square Logo"
          className="h-auto w-auto max-h-[52px] max-w-[128px] object-contain cursor-pointer"
        />

        {/* Desktop Navigation */}
        <NavigationMenu className="hidden md:flex">
          <NavigationMenuList>
            {menuItems.map((item) => (
              <NavigationMenuItem key={item}>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className="rounded-md inline-flex"
                >
                  <NavigationMenuLink
                    className={`group inline-flex h-10 items-center justify-center rounded-md px-4 py-2 text-sm font-semibold ${
                      isHovered || isScrolled
                        ? "text-black text-lg font-bold"
                        : "text-outline-black text-lg font-bold  "
                    }  transition-colors hover:text-blue-600 focus:text-blue-600 focus:outline-none`}
                    href={`/${item.toLowerCase().replace(" ", "-")}`}
                  >
                    {item}
                  </NavigationMenuLink>
                </motion.div>
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>

        {/* Desktop Language Selector with Dropdown */}
        <div className="relative hidden md:block">
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-2 bg-blue-500 text-white-a700 hover:bg-blue-600"
            onClick={toggleLanguageMenu}
          >
            <Globe className="h-4 w-4" />
            <span>{selectedLanguage}</span>
          </Button>
          {isLanguageOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
              className="absolute right-0 mt-2 w-32 bg-white-a700 shadow-lg rounded-md overflow-hidden"
            >
              {["English", "Vietnamese", "Korean"].map((language) => (
                <button
                  key={language}
                  onClick={() => handleLanguageSelect(language)}
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-blue-100"
                >
                  {language}
                </button>
              ))}
            </motion.div>
          )}
        </div>

        {/* Mobile Menu Button */}
        <Button
          variant="outline"
          size="icon"
          className="md:hidden"
          onClick={() => setIsMenuOpen(true)}
        >
          <Menu className="h-6 w-6" />
          <span className="sr-only">Open menu</span>
        </Button>

        {/* Mobile Navigation Content with Framer Motion */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className="fixed top-0 right-0 h-full max-w-[75vw] w-full bg-white-a700 z-50 p-6 shadow-lg overflow-y-auto"
            >
              {/* Close Button */}
              <Button
                variant="outline"
                size="icon"
                className="absolute top-4 right-4"
                onClick={() => setIsMenuOpen(false)}
              >
                <X className="h-6 w-6" />
                <span className="sr-only">Close menu</span>
              </Button>

              <nav className="flex flex-col space-y-4 mt-8">
                {menuItems.map((item) => (
                  <motion.a
                    key={item}
                    href={`/${item.toLowerCase().replace(" ", "-")}`}
                    className="text-lg font-medium text-black hover:text-blue-600 transition-colors"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.1 }}
                    onClick={() => setIsMenuOpen(false)} // Close menu on link click
                  >
                    {item}
                  </motion.a>
                ))}
              </nav>

              {/* Mobile Language Selector with Dropdown */}
              <div className="relative mt-6">
                <Button
                  variant="outline"
                  size="sm"
                  className="flex items-center gap-2 bg-blue-500 text-white-a700 hover:bg-blue-600"
                  onClick={toggleLanguageMenu}
                >
                  <Globe className="h-4 w-4" />
                  <span>{selectedLanguage}</span>
                </Button>
                {isLanguageOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                    className="absolute left-0 mt-2 w-32 bg-white-a700 shadow-lg rounded-md overflow-hidden"
                  >
                    {["English", "Vietnamese", "Korean"].map((language) => (
                      <button
                        key={language}
                        onClick={() => handleLanguageSelect(language)}
                        className="block w-full text-left px-4 py-2 text-white-a700 hover:bg-blue-100"
                      >
                        {language}
                      </button>
                    ))}
                  </motion.div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
}
