"use client";

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Star, Truck, RotateCcw, ShieldCheck, Phone, X } from "lucide-react";
import { Button } from "../../../../components/ui/Button";
import { Card, CardContent } from "../../../../components/ui/Card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../../components/ui/Tabs";
import Layout from "../../../layout";

// Define the product type
type Product = {
  id: number;
  name: string;
  price: number;
  rating: number;
  reviews: number;
  description: string;
  features: string[];
  specs: Record<string, string>;
  images: string[];
};

// Define the recommended product type
type RecommendedProduct = {
  id: number;
  name: string;
  price: number;
  image: string;
};

const product: Product = {
  id: 1,
  name: "Advanced AI-Powered Drone",
  price: 1299.99,
  rating: 4.8,
  reviews: 124,
  description:
    "Experience the future of aerial technology with our Advanced AI-Powered Drone. Equipped with state-of-the-art artificial intelligence, this drone offers unparalleled autonomous flight capabilities, obstacle avoidance, and intelligent tracking features.",
  features: [
    "4K Ultra HD Camera with 3-axis gimbal stabilization",
    "45 minutes flight time",
    "5 mile transmission range",
    "Obstacle sensing in all directions",
    "AI-powered autonomous flight modes",
    "Foldable design for easy portability",
  ],
  specs: {
    Dimensions: "13.2 x 9.5 x 3.3 inches (folded)",
    Weight: "2.2 lbs (995 g)",
    "Max Speed": "42 mph (68 km/h)",
    "Internal Storage": "8 GB",
    "Compatible Memory Card": "MicroSD up to 256 GB",
    "Operating Temperature": "14° to 104°F (-10° to 40°C)",
  },
  images: [
    "/placeholder.svg?height=400&width=600",
    "/placeholder.svg?height=400&width=600",
    "/placeholder.svg?height=400&width=600",
  ],
};

const recommendedProducts: RecommendedProduct[] = [
  {
    id: 2,
    name: "Drone Carrying Case",
    price: 89.99,
    image: "/placeholder.svg?height=200&width=300",
  },
  {
    id: 3,
    name: "Extra Battery Pack",
    price: 129.99,
    image: "/placeholder.svg?height=200&width=300",
  },
  {
    id: 4,
    name: "Propeller Guards Set",
    price: 29.99,
    image: "/placeholder.svg?height=200&width=300",
  },
];

interface ImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  src: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ isOpen, onClose, src }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.8 }}
          className="relative"
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={src}
            alt="Enlarged view"
            className="max-w-full max-h-[90vh] object-contain"
          />
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white-a700 bg-black bg-opacity-50 rounded-full p-2"
          >
            <X size={24} />
          </button>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

export default function DronesProductDetails() {
  const [mainImage, setMainImage] = useState<string>(product.images[0]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalImage, setModalImage] = useState<string>("");

  const openModal = (image: string) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8 pt-24">
        <motion.div
          className="grid md:grid-cols-2 gap-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Product Images */}
          <div>
            <motion.img
              src={mainImage}
              alt={product.name}
              className="w-full h-96 object-cover rounded-lg shadow-lg mb-4 cursor-pointer"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              onClick={() => openModal(mainImage)}
            />
            <div className="grid grid-cols-3 gap-4">
              {product.images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`${product.name} view ${index + 1}`}
                  className="w-full h-24 object-cover rounded-md cursor-pointer hover:opacity-75 transition-opacity"
                  onClick={() => {
                    setMainImage(img);
                    openModal(img);
                  }}
                />
              ))}
            </div>
          </div>

          {/* Product Info */}
          <div>
            <h1 className="text-3xl font-bold mb-2">{product.name}</h1>
            <div className="flex items-center mb-4">
              <div className="flex items-center mr-2">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className={`w-5 h-5 ${
                      i < Math.floor(product.rating)
                        ? "text-yellow-400 fill-current"
                        : "text-gray-300"
                    }`}
                  />
                ))}
              </div>
              <span className="text-sm text-gray-600">
                ({product.reviews} reviews)
              </span>
            </div>
            <p className="text-2xl font-bold mb-4">
              ${product.price.toFixed(2)}
            </p>
            <p className="text-gray-600 mb-6">{product.description}</p>

            <div className="mb-6">
              <Button className="w-full bg-blue-600 hover:bg-blue-700 text-white-a700">
                <Phone className="mr-2 h-4 w-4" /> Contact for Inquiry
              </Button>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-6">
              <Card>
                <CardContent className="flex items-center p-4">
                  <Truck className="w-6 h-6 mr-2 text-blue-600" />
                  <span className="text-sm">Free Shipping</span>
                </CardContent>
              </Card>
              <Card>
                <CardContent className="flex items-center p-4">
                  <RotateCcw className="w-6 h-6 mr-2 text-blue-600" />
                  <span className="text-sm">30-Day Returns</span>
                </CardContent>
              </Card>
              <Card>
                <CardContent className="flex items-center p-4">
                  <ShieldCheck className="w-6 h-6 mr-2 text-blue-600" />
                  <span className="text-sm">2-Year Warranty</span>
                </CardContent>
              </Card>
            </div>
          </div>
        </motion.div>

        {/* Product Details Tabs */}
        <Tabs defaultValue="features" className="mt-12">
          <TabsList className="grid w-full grid-cols-3 ">
            <TabsTrigger
              value="features"
              className="py-2 px-4 rounded-md focus:outline-none bg-white-a700 text-black
      aria-selected:bg-blue-700 aria-selected:text-white-a700"
            >
              Features
            </TabsTrigger>
            <TabsTrigger
              value="specs"
              className="py-2 px-4 rounded-md focus:outline-none bg-white-a700 text-black
      aria-selected:bg-blue-700 aria-selected:text-white-a700"
            >
              Specifications
            </TabsTrigger>
            <TabsTrigger
              value="reviews"
              className="py-2 px-4 rounded-md focus:outline-none bg-white-a700 text-black
      aria-selected:bg-blue-700 aria-selected:text-white-a700 
     "
            >
              Reviews
            </TabsTrigger>
          </TabsList>
          <TabsContent value="features">
            <Card>
              <CardContent className="pt-6">
                <ul className="list-disc pl-5 space-y-2">
                  {product.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="specs">
            <Card>
              <CardContent className="pt-6">
                <dl className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                  {Object.entries(product.specs).map(([key, value]) => (
                    <React.Fragment key={key}>
                      <dt className="font-semibold">{key}:</dt>
                      <dd>{value}</dd>
                    </React.Fragment>
                  ))}
                </dl>
              </CardContent>
            </Card>
          </TabsContent>
          <TabsContent value="reviews">
            <Card>
              <CardContent className="pt-6">
                <p>
                  Reviews content here. This could be expanded into a full
                  review system with user comments and ratings.
                </p>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>

        {/* Recommended Products */}
        <section className="mt-16">
          <h2 className="text-2xl font-bold mb-6">Recommended Products</h2>
          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ staggerChildren: 0.1 }}
          >
            {recommendedProducts.map((recProduct) => (
              <motion.div
                key={recProduct.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Card className="h-full flex flex-col">
                  <CardContent className="p-4 flex-grow">
                    <img
                      src={recProduct.image}
                      alt={recProduct.name}
                      className="w-full h-48 object-cover rounded-md mb-4 cursor-pointer"
                      onClick={() => openModal(recProduct.image)}
                    />
                    <h3 className="font-semibold mb-2">{recProduct.name}</h3>
                    <p className="text-lg font-bold">
                      ${recProduct.price.toFixed(2)}
                    </p>
                  </CardContent>
                  <CardContent className="p-4 pt-0">
                    <Button className="w-full" variant="outline">
                      View Details
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </motion.div>
        </section>

        {/* Image Modal */}
        <ImageModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          src={modalImage}
        />
      </div>
    </Layout>
  );
}

// Fix for isolatedModules
export {};
