import { createRoot } from "react-dom/client";
import App from "./App";
import "./styles/global.css";
import "./styles/index.css"; // Fix the typo in this line (".styles" to "./styles")
import "./styles/font.css";

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  console.error("Root container not found.");
}
