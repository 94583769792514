import React, { useRef, useState, useEffect } from "react";
import { motion, useInView, AnimatePresence } from "framer-motion";
import {
  Users,
  Award,
  BarChart,
  CheckCircle,
  Plane,
  Bot,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";

import { Text } from "../../components/ui/Text";
import { Button } from "../../components/ui/Button";
import { Img } from "../../components/ui/Img";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/ui/Card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/Accordion";
import { useNavigate } from "react-router-dom";

// Animation Variants for each section
const leftToCenterAnimation = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 2, ease: "easeOut" } },
};
const rightToCenterAnimation = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { duration: 2, ease: "easeOut" } },
};
const statsPopInAnimation = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 1.5, ease: "easeOut", staggerChildren: 0.15 },
  },
};
const productAnimation = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 2, ease: "easeOut", staggerChildren: 0.2 },
  },
};
// Define the list of all partners
const allPartners = [
  {
    id: 1,
    title: "Best Choice",
    image: "/Images/logo/asomeit.png",
  },
  {
    id: 2,
    title: "Premium Quality",
    image: "/Images/logo/bis.png",
  },
  {
    id: 3,
    title: "1st Choice",
    image: "/Images/logo/bk.png",
  },
  {
    id: 4,
    title: "Highest Quality",
    image: "/Images/logo/CIS.png",
  },
  {
    id: 5,
    title: "Limited Edition",
    image: "/Images/logo/dcu.png",
  },
  {
    id: 6,
    title: "1st Choice Premium",
    image: "/Images/logo/Drone division.png",
  },
  {
    id: 7,
    title: "Limited Edition Premium",
    image: "/Images/logo/GBICT.png",
  },
  { id: 8, title: "Top Tier", image: "/Images/logo/HUTECH.png" },
  {
    id: 9,
    title: "Elite Choice",
    image: "/Images/logo/IS.png",
  },
  {
    id: 10,
    title: "Premium Selection",
    image: "/Images/logo/jeonnam.png",
  },
  {
    id: 11,
    title: "1st Choice",
    image: "/Images/logo/royal.png",
  },
  {
    id: 12,
    title: "Highest Quality",
    image: "/Images/logo/SAMSUNG.png",
  },
  {
    id: 13,
    title: "Limited Edition",
    image: "/Images/logo/sb.png",
  },
  {
    id: 14,
    title: "1st Choice Premium",
    image: "/Images/logo/SIKS.png",
  },
  {
    id: 15,
    title: "Limited Edition Premium",
    image: "/Images/logo/SSIS.png",
  },
  { id: 16, title: "Top Tier", image: "/Images/logo/tdt.png" },
  {
    id: 17,
    title: "Elite Choice",
    image: "/Images/logo/IS/UEF",
  },
  {
    id: 18,
    title: "Premium Selection",
    image: "/Images/logo/vas.png",
  },
];

// About Us Section
const AboutUsSection: React.FC = () => {
  const aboutRef = useRef(null);
  const isAboutInView = useInView(aboutRef, { once: true });
  const navigate = useNavigate(); // Initialize navigate function

  return (
    <motion.div
      ref={aboutRef}
      initial="hidden"
      animate={isAboutInView ? "visible" : "hidden"}
      className="mt-[120px] flex justify-center px-5"
    >
      <div className="mx-auto flex w-full max-w-[1200px] items-center gap-8 flex-col lg:flex-row lg:gap-12 pb-20">
        <motion.div
          className="relative h-auto flex-1 flex items-center justify-center w-full lg:w-1/2 mb-8 lg:mb-0"
          variants={leftToCenterAnimation}
        >
          <Img
            src="Images/LandingPage/AboutUs.jpg"
            alt="Background image"
            className="relative h-[400px] w-[320px] sm:h-[480px] sm:w-[380px] lg:h-[560px] lg:w-full rounded-[16px] object-cover shadow-lg "
            referrerPolicy="no-referrer" // Disables the referrer header, preventing tracking
            loading="lazy"
          />
        </motion.div>
        <motion.div
          className="flex w-full lg:w-1/2 flex-col items-start gap-6 lg:gap-8"
          variants={rightToCenterAnimation}
        >
          <Text
            size="textmd"
            className="w-full font-roboto text-[36px] font-semibold leading-[44px] tracking-[-0.84px] text-gray-900 md:text-[32px] sm:text-[28px]"
          >
            Our Legacy of Trust and Precision
          </Text>
          <Text
            size="textmd"
            className="text-justify text-[16px] font-normal leading-6 tracking-[-0.32px] text-gray-700"
          >
            Pulvinar aliquam sagittis nam vel eu. Gravida amet tincidunt
            lobortis diam cras in. Quis ultricies at ac mauris malesuada sodales
            molestie dolor rutrum. Nunc commodo tellus risus egestas duis duis
            morbi senectus.
          </Text>
          <Button
            size="sm"
            className="bg-blue-500 text-white-a700 px-6 py-2 rounded-md font-roboto"
            onClick={() => navigate("/about")} // Navigate to the About page
          >
            About Us
          </Button>
        </motion.div>
      </div>
    </motion.div>
  );
};

// Stats Section
const StatsSection: React.FC = () => {
  const statsRef = useRef(null);
  const isStatsInView = useInView(statsRef, { once: true });

  return (
    <motion.section
      ref={statsRef}
      initial="hidden"
      animate={isStatsInView ? "visible" : "hidden"}
      variants={statsPopInAnimation}
      className="py-20 bg-gray-50"
    >
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {[
            { number: "15K", label: "Active Users", icon: Users },
            { number: "100+", label: "Team members", icon: Award },
            {
              number: "98%",
              label: "Customer satisfaction",
              icon: CheckCircle,
            },
            { number: "100%", label: "Growth rate", icon: BarChart },
          ].map((stat, index) => (
            <motion.div key={index} className="text-center">
              <stat.icon className="w-8 h-8 mx-auto mb-4 text-blue-500" />
              <Text size="textxl" className="font-bold mb-2">
                {stat.number}
              </Text>
              <Text size="texts" className="text-gray-600">
                {stat.label}
              </Text>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

// Products Section

const products = [
  {
    id: 1,
    name: "Box megaton",
    description: "High-quality AI education.",
    imagePath: "Images/megaton/box megaton_.png",
  },
  {
    id: 2,
    name: "Megaton",
    description: "AI Application tools.",
    imagePath: "Images/megaton/MEGATON.png",
  },
  {
    id: 3,
    name: "Megaton Board",
    description: "Megaton Board for AI coding.",
    imagePath: "Images/megaton/megatonboard.png",
  },
];

const ProductsSection: React.FC = () => {
  const productsRef = useRef(null);
  const isProductsInView = useInView(productsRef, { once: true });

  return (
    <motion.section
      ref={productsRef}
      initial="hidden"
      animate={isProductsInView ? "visible" : "hidden"}
      variants={productAnimation}
      className="py-20"
    >
      <div className="container mx-auto px-4">
        <Text size="textxl" className="text-center font-bold  mb-12">
          Our Products
        </Text>
        <div className="grid md:grid-cols-3 gap-8">
          {products.map((product) => (
            <motion.div
              key={product.id}
              initial={{ opacity: 0, y: 30 }}
              animate={isProductsInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, delay: product.id * 0.2 }}
            >
              <Card className="overflow-hidden">
                <CardHeader className="p-0">
                  <Img
                    src={product.imagePath}
                    alt={`${product.name} image`}
                    width={400}
                    height={300}
                    className="w-full h-48 object-contain"
                  />
                  <CardTitle className="p-4">{product.name}</CardTitle>
                </CardHeader>
                <CardContent>
                  <Text size="texts" className="text-gray-600">
                    {product.description}
                  </Text>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

// Program Showcase Section

const programData = {
  roboticAI: {
    title: "Robotic AI Program",
    description:
      "Explore the cutting-edge intersection of robotics and artificial intelligence.",
    descriptionImage: "/placeholder.svg?height=200&width=300",
    images: [
      "/placeholder.svg?height=400&width=600&text=Robotic+AI+1",
      "/placeholder.svg?height=400&width=600&text=Robotic+AI+2",
      "/placeholder.svg?height=400&width=600&text=Robotic+AI+3",
    ],
    features: [
      "Advanced machine learning algorithms",
      "Robotic process automation",
      "Computer vision and image recognition",
      "Natural language processing for human-robot interaction",
    ],
  },
  drones: {
    title: "Drone Technology Program",
    description:
      "Master the art and science of unmanned aerial vehicles and their applications.",
    descriptionImage: "/placeholder.svg?height=200&width=300",
    images: [
      "/placeholder.svg?height=400&width=600&text=Drones+1",
      "/placeholder.svg?height=400&width=600&text=Drones+2",
      "/placeholder.svg?height=400&width=600&text=Drones+3",
    ],
    features: [
      "Autonomous flight systems",
      "Aerial photography and videography",
      "Drone mapping and surveying",
      "Search and rescue operations",
    ],
  },
};
const ProgramShowcase: React.FC = () => {
  const [activeProgram, setActiveProgram] = useState<"roboticAI" | "drones">(
    "roboticAI"
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const setProgram = (program: "roboticAI" | "drones") => {
    setActiveProgram(program);
  };
  const nextImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % programData[activeProgram].images.length
    );
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + programData[activeProgram].images.length) %
        programData[activeProgram].images.length
    );
  };
  // Ref and visibility tracking for each section element
  const buttonsRef = useRef(null);
  const buttonsInView = useInView(buttonsRef, { once: true });

  const cardTitleRef = useRef(null);
  const cardTitleInView = useInView(cardTitleRef, { once: true });

  const featuresRef = useRef(null);
  const featuresInView = useInView(featuresRef, { once: true });

  return (
    <motion.section
      className="py-20 bg-slate-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="container mx-auto px-6 lg:px-8">
        <motion.h2
          className="text-4xl font-bold text-center mb-10 text-gray-800"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Our Innovative Programs
        </motion.h2>

        {/* Program Selection Buttons */}
        <div ref={buttonsRef} className="flex flex-col items-center mb-12">
          <motion.div
            className="flex space-x-6 mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={buttonsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <Button
              onClick={() => setProgram("roboticAI")}
              variant={activeProgram === "roboticAI" ? "default" : "outline"}
              className={`${
                activeProgram === "roboticAI"
                  ? "bg-blue-600 text-white hover:bg-blue-700"
                  : "bg-transparent border-blue-600 text-blue-600 hover:bg-blue-100"
              }`}
            >
              Robotic AI
              <Bot className="ml-2 h-4 w-4" />
            </Button>
            <Button
              onClick={() => setProgram("drones")}
              variant={activeProgram === "drones" ? "default" : "outline"}
              className={`${
                activeProgram === "drones"
                  ? "bg-blue-600 text-white hover:bg-blue-700"
                  : "bg-transparent border-blue-600 text-blue-600 hover:bg-blue-100"
              }`}
            >
              Drones
              <Plane className="ml-2 h-4 w-4" />
            </Button>
          </motion.div>

          {/* Program Details */}
          <AnimatePresence mode="wait">
            <motion.div
              key={activeProgram}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.4 }}
              className="w-full max-w-5xl"
            >
              <Card className="p-8 bg-white shadow-lg rounded-lg border border-gray-200">
                <CardHeader>
                  <div className="flex flex-col lg:flex-row items-center justify-between gap-6 lg:gap-10">
                    <motion.div
                      className="flex-1"
                      ref={cardTitleRef}
                      initial={{ opacity: 0, x: -20 }}
                      animate={cardTitleInView ? { opacity: 1, x: 0 } : {}}
                      transition={{ duration: 0.5 }}
                    >
                      <CardTitle className="text-3xl mb-4 text-gray-800">
                        {programData[activeProgram].title}
                      </CardTitle>
                      <CardDescription className="text-lg text-gray-600">
                        {programData[activeProgram].description}
                      </CardDescription>
                    </motion.div>
                    <motion.img
                      src={programData[activeProgram].descriptionImage}
                      alt={`${programData[activeProgram].title} illustration`}
                      className="w-full lg:w-1/3 h-auto object-cover rounded-lg shadow-md"
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={cardTitleInView ? { opacity: 1, scale: 1 } : {}}
                      transition={{ duration: 0.5, delay: 0.2 }}
                    />
                  </div>
                </CardHeader>

                <CardContent className="mt-8">
                  <div className="flex flex-col lg:flex-row gap-8">
                    <div className="w-full lg:w-1/2 relative">
                      <AnimatePresence mode="wait">
                        <motion.img
                          key={currentImageIndex}
                          src={
                            programData[activeProgram].images[currentImageIndex]
                          }
                          alt={`${programData[activeProgram].title} - Image ${
                            currentImageIndex + 1
                          }`}
                          className="w-full h-auto object-cover rounded-lg shadow-md"
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: 20 }}
                          transition={{ duration: 0.5 }}
                        />
                      </AnimatePresence>
                      <div className="absolute top-1/2 left-0 right-0 flex justify-between transform -translate-y-1/2">
                        <Button
                          variant="outline"
                          size="icon"
                          className="bg-white/80 hover:bg-white"
                          onClick={prevImage}
                          aria-label="Previous image"
                        >
                          <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="outline"
                          size="icon"
                          className="bg-white/80 hover:bg-white"
                          onClick={nextImage}
                          aria-label="Next image"
                        >
                          <ChevronRight className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                    <ul
                      ref={featuresRef}
                      className="list-disc pl-5 space-y-3 flex-1 text-gray-700"
                    >
                      {programData[activeProgram].features.map(
                        (feature, index) => (
                          <motion.li
                            key={index}
                            initial={{ opacity: 0, x: -20 }}
                            animate={featuresInView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.3, delay: index * 0.15 }}
                            className="text-lg"
                          >
                            {feature}
                          </motion.li>
                        )
                      )}
                    </ul>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </motion.section>
  );
};

// Partners Section
const PartnersSection: React.FC = () => {
  const [visiblePartners, setVisiblePartners] = useState(
    allPartners.slice(0, 8)
  );
  const [isMobile, setIsMobile] = useState(false);

  // Check for screen size
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const interval = setInterval(() => {
        setVisiblePartners((prevPartners) => {
          const newPartners = [...prevPartners];
          const removedPartner = newPartners.shift();
          const nextPartner = allPartners.find(
            (p) => !prevPartners.includes(p)
          );
          if (nextPartner) {
            newPartners.push(nextPartner);
          } else if (removedPartner) {
            newPartners.push(removedPartner);
          }
          return newPartners;
        });
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isMobile]);

  const containerRef = useRef(null);
  const containerInView = useInView(containerRef, { once: true });

  return (
    <section className="py-16 bg-[#FAF9F9]" ref={containerRef}>
      <div className="container mx-auto px-4">
        <motion.div
          className="text-center max-w-3xl mx-auto mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={containerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 2 }}
        >
          <motion.h2
            className="text-[2.5rem] font-bold text-[#1A1A1A] mb-4"
            initial={{ opacity: 0, y: -20 }}
            animate={containerInView ? { opacity: 1, y: 0 } : {}}
          >
            Our Trustful Partners
          </motion.h2>
          <motion.p
            className="text-gray-600"
            initial={{ opacity: 0 }}
            animate={containerInView ? { opacity: 1 } : {}}
            transition={{ duration: 2, delay: 1.2 }}
          >
            Collaborating for mutual growth and success with industry leaders.
          </motion.p>
        </motion.div>

        {isMobile ? (
          <div className="grid grid-cols-2 gap-4 justify-items-center">
            {allPartners.map((partner, index) => (
              <motion.div
                key={partner.id}
                className="w-32 h-32 flex items-center justify-center transition-transform duration-300 hover:scale-105"
                initial={{ opacity: 0, y: 20 }}
                animate={containerInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{
                  boxShadow: "0 0 10px 4px rgba(0, 123, 255, 0.5)",
                }}
              >
                <img
                  src={partner.image}
                  alt={partner.title}
                  className="w-full h-full object-contain filter grayscale hover:grayscale-0 transition-all duration-300"
                />
              </motion.div>
            ))}
          </div>
        ) : (
          <motion.div
            ref={containerRef}
            className="grid grid-cols-4 gap-8 items-center justify-items-center"
            initial="hidden"
            animate={containerInView ? "visible" : "hidden"}
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
            }}
          >
            <AnimatePresence mode="popLayout">
              {visiblePartners.map((partner, index) => (
                <motion.div
                  key={partner.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="w-32 h-32 flex items-center justify-center"
                >
                  <img
                    src={partner.image}
                    alt={partner.title}
                    className="w-full h-full object-contain filter  transition-all duration-300"
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        )}
      </div>
    </section>
  );
};

// FAQ Section
const FAQSection: React.FC = () => {
  const faqRef = useRef(null);
  const faqInView = useInView(faqRef, { once: true });

  return (
    <motion.section
      className="py-20"
      ref={faqRef}
      initial={{ opacity: 0 }}
      animate={faqInView ? { opacity: 1 } : {}}
      transition={{ duration: 2 }}
    >
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-center font-bold mb-4 text-gray-900 text-4xl"
          initial={{ opacity: 0, y: -20 }}
          animate={faqInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          Frequently Asked Questions
        </motion.h2>
        <motion.p
          className="text-center text-gray-500 mb-12"
          initial={{ opacity: 0 }}
          animate={faqInView ? { opacity: 1 } : {}}
          transition={{ duration: 1.6, delay: 1.2 }}
        >
          Risus vitae penatibus consequat pellentesque consequat tempor.
        </motion.p>
        <div className="max-w-3xl mx-auto">
          <Accordion type="single" collapsible>
            {[
              {
                question: "What services are included in the Basic Plan?",
                answer:
                  "Rorem ipsum dolor sit amet consectetur. Sit est nunc proin tellus posuere egestas vitae morbi. Sed molestie nam ut at non facilisis fames nunc ut.",
              },
              {
                question: "Can I upgrade my plan as my business grows?",
                answer:
                  "Yes, you can upgrade your plan at any time to suit your growing business needs.",
              },
              {
                question: "How does the custom plan work?",
                answer:
                  "Our custom plan is tailored to your unique requirements. Contact us for a consultation.",
              },
              {
                question: "How do I get started with your services?",
                answer:
                  "Getting started is easy! Simply contact our team, and we'll guide you through the process.",
              },
            ].map((faq, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <motion.div
                  className="bg-white-a700 rounded-lg shadow-sm mb-3"
                  initial={{ opacity: 0, y: 20 }}
                  animate={faqInView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 1.5, delay: index * 0.1 }}
                >
                  <AccordionTrigger className="font-medium text-gray-800 px-4 py-3 w-full text-left">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent className="px-4 py-2 text-gray-600">
                    {faq.answer}
                  </AccordionContent>
                </motion.div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </motion.section>
  );
};

// Summer Camp Section
const SummerCampSection: React.FC = () => {
  const summerCampRef = useRef(null);
  const isSummerCampInView = useInView(summerCampRef, { once: true });
  const navigate = useNavigate(); // Import useNavigate from react-router-dom

  const highlights = [
    {
      icon: Award,
      title: "Award-Winning Curriculum",
      description: "Tailored programs that inspire young minds.",
    },
    {
      icon: Users,
      title: "Experienced Instructors",
      description: "Certified experts in robotics and AI.",
    },
    {
      icon: Plane,
      title: "Hands-On Learning",
      description: "Engaging activities with real-world applications.",
    },
    {
      icon: CheckCircle,
      title: "Safe & Fun Environment",
      description: "Ensuring safety while having the best summer experience.",
    },
  ];

  return (
    <motion.section
      ref={summerCampRef}
      initial="hidden"
      animate={isSummerCampInView ? "visible" : "hidden"}
      className="py-20 bg-gradient-to-br from-blue-50 to-white"
    >
      <div className="container mx-auto px-6 lg:px-8">
        {/* Heading and Description */}
        <motion.div
          className="text-center mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={isSummerCampInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          <Text size="textxl" className="font-bold text-gray-800 mb-4">
            Summer Camp 2024
          </Text>
          <Text size="textmd" className="text-gray-600">
            Embark on a summer of discovery and innovation! Our robotics and AI
            summer camp is designed to spark creativity, foster teamwork, and
            unlock the potential of tomorrow's innovators.
          </Text>
        </motion.div>

        {/* Highlights */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {highlights.map((highlight, index) => (
            <motion.div
              key={index}
              className="text-center p-6 bg-white shadow-lg rounded-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={isSummerCampInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, delay: index * 0.2 }}
            >
              <highlight.icon className="w-12 h-12 mx-auto text-blue-500 mb-4" />
              <Text size="textlg" className="font-semibold text-gray-800 mb-2">
                {highlight.title}
              </Text>
              <Text size="texts" className="text-gray-600">
                {highlight.description}
              </Text>
            </motion.div>
          ))}
        </div>

        {/* Call to Action */}
        <motion.div
          className="text-center"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={isSummerCampInView ? { opacity: 1, scale: 1 } : {}}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <div className="flex flex-col items-center space-y-4">
            <Button
              size="lg"
              className="bg-blue-600 text-white hover:bg-blue-700 px-8 py-4 rounded-md"
              onClick={() => console.log("Register for Summer Camp")}
            >
              Register Now
            </Button>
            <Button
              size="lg"
              variant="outline"
              className="border-blue-600 text-blue-600 hover:bg-blue-100 px-8 py-4 rounded-md"
              onClick={() => navigate("/summer-camp")}
            >
              Learn More
            </Button>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

// Main Landing Page Component
const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-white">
      <AboutUsSection />
      <StatsSection />
      <SummerCampSection />
      <ProductsSection />

      <ProgramShowcase />
      <PartnersSection />
      <FAQSection />
    </div>
  );
};

export default LandingPage;
