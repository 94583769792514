"use client";

import React, { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "../../components/ui/Button";

import {
  Clock,
  User,
  DrillIcon as Drone,
  BotIcon as Robot,
  Book,
} from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/Card";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/Tabs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/Accordion";
import Layout from "../layout";

const courses = [
  {
    id: 1,
    title: "Introduction to Drone Technology",
    image: "/placeholder.svg?height=200&width=300",
    instructor: "Dr. Jane Smith",
    description:
      "Learn the basics of drone technology, including flight principles, components, and applications.",
    duration: "6 weeks",
    category: "drones",
    featured: true,
  },
  {
    id: 2,
    title: "Advanced Robotics Programming",
    image: "/placeholder.svg?height=200&width=300",
    instructor: "Prof. John Doe",
    description:
      "Dive deep into robotics programming, covering topics such as motion planning, computer vision, and machine learning for robots.",
    duration: "8 weeks",
    category: "robotics",
    featured: true,
  },
  {
    id: 3,
    title: "Drone and Robot Integration",
    image: "/placeholder.svg?height=200&width=300",
    instructor: "Dr. Emily Johnson",
    description:
      "Explore the synergy between drones and robots, learning how to create integrated systems for complex tasks.",
    duration: "10 weeks",
    category: "both",
    featured: true,
  },
];

const testimonials = [
  {
    id: 1,
    name: "John Smith",
    course: "Introduction to Drone Technology",
    quote:
      "This course opened my eyes to the incredible potential of drone technology. Highly recommended!",
  },
  {
    id: 2,
    name: "Emily Davis",
    course: "Advanced Robotics Programming",
    quote:
      "The hands-on projects in this course really helped me understand complex robotics concepts. Great experience!",
  },
  {
    id: 3,
    name: "Michael Johnson",
    course: "Drone and Robot Integration",
    quote:
      "Combining drones and robots in this course was fascinating. It's given me new ideas for my research projects.",
  },
];

const faqs = [
  {
    question: "What prerequisites are required for these courses?",
    answer:
      "Prerequisites vary by course. Generally, a basic understanding of programming and mathematics is recommended. Specific requirements are listed in each course description.",
  },
  {
    question: "Are these courses suitable for beginners?",
    answer:
      "We offer courses for all skill levels. Courses like 'Introduction to Drone Technology' are perfect for beginners, while others may require more advanced knowledge.",
  },
  {
    question: "How much time should I dedicate to these courses each week?",
    answer:
      "Most courses require 5-10 hours per week, including lectures, readings, and hands-on projects. The exact time commitment is specified in each course description.",
  },
  {
    question: "Will I receive a certificate upon completion?",
    answer:
      "Yes, upon successful completion of a course, you will receive a digital certificate that you can share on your resume or LinkedIn profile.",
  },
];

export default function EnhancedCourseScreen() {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const filteredCourses =
    selectedCategory === "all"
      ? courses
      : courses.filter(
          (course) =>
            course.category === selectedCategory || course.category === "both"
        );

  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <Layout>
      <div className="bg-gray-100 min-h-screen pt-20 ">
        {/* Hero Section */}
        <motion.section
          className="bg-gradient-to-r from-blue-600 to-purple-600 text-white-a700 py-20"
          initial="hidden"
          whileInView="visible"
          variants={sectionVariants}
        >
          <div className="container mx-auto px-4 text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              Explore the Future of Technology
            </h1>
            <p className="text-xl mb-8">
              Dive into the world of drones and robotics with our cutting-edge
              courses
            </p>
            <Button size="lg" variant="secondary">
              Get Started
            </Button>
          </div>
        </motion.section>

        {/* Filter Section */}
        <motion.section
          className="bg-white-a700 py-16"
          initial="hidden"
          whileInView="visible"
          variants={sectionVariants}
        >
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center text-white">
              Filter Courses
            </h2>
            <Tabs defaultValue="all" className="mb-8">
              <TabsList className="grid w-full grid-cols-3">
                <TabsTrigger
                  className={`${
                    selectedCategory === "all"
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-black hover:bg-gray-300"
                  }`}
                  value="all"
                  onClick={() => setSelectedCategory("all")}
                >
                  All Courses
                </TabsTrigger>
                <TabsTrigger
                  className={`${
                    selectedCategory === "drones"
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-black hover:bg-gray-300"
                  }`}
                  value="drones"
                  onClick={() => setSelectedCategory("drones")}
                >
                  Drones
                </TabsTrigger>
                <TabsTrigger
                  className={`${
                    selectedCategory === "robotics"
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-black hover:bg-gray-300"
                  }`}
                  value="robotics"
                  onClick={() => setSelectedCategory("robotics")}
                >
                  Robotics
                </TabsTrigger>
              </TabsList>
            </Tabs>
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              initial="hidden"
              animate="visible"
              variants={sectionVariants}
            >
              {filteredCourses.map((course) => (
                <Card key={course.id} className="h-full flex flex-col">
                  <CardHeader>
                    <img
                      src={course.image}
                      alt={course.title}
                      className="w-full h-48 object-cover rounded-t-lg"
                    />
                  </CardHeader>
                  <CardContent className="flex-grow">
                    <CardTitle className="mb-2">{course.title}</CardTitle>
                    <CardDescription className="mb-4">
                      {course.description}
                    </CardDescription>
                    <div className="flex items-center text-sm text-gray-500 mb-2">
                      <User className="mr-2 h-4 w-4" />
                      {course.instructor}
                    </div>
                    <div className="flex items-center text-sm text-gray-500">
                      <Clock className="mr-2 h-4 w-4" />
                      {course.duration}
                    </div>
                  </CardContent>
                  <CardFooter>
                    <Button
                      className="w-full bg-blue-600 text-white hover:bg-blue-700"
                      onClick={() =>
                        console.log(`Learn more about ${course.title}`)
                      }
                    >
                      Learn More
                    </Button>
                  </CardFooter>
                </Card>
              ))}
            </motion.div>
          </div>
        </motion.section>

        {/* Testimonials */}
        <motion.section
          className="bg-gray-200 py-16"
          initial="hidden"
          whileInView="visible"
          variants={sectionVariants}
        >
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">
              What Our Students Say
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {testimonials.map((testimonial) => (
                <Card key={testimonial.id}>
                  <CardContent className="pt-6">
                    <p className="italic mb-4">"{testimonial.quote}"</p>
                    <p className="font-semibold">{testimonial.name}</p>
                    <p className="text-sm text-gray-500">
                      {testimonial.course}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </motion.section>

        {/* FAQ */}
        <motion.section
          className="py-16"
          initial="hidden"
          whileInView="visible"
          variants={sectionVariants}
        >
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">
              Frequently Asked Questions
            </h2>
            <Accordion
              type="single"
              collapsible
              className="w-full max-w-3xl mx-auto"
            >
              {faqs.map((faq, index) => (
                <AccordionItem key={index} value={`item-${index}`}>
                  <AccordionTrigger>{faq.question}</AccordionTrigger>
                  <AccordionContent>{faq.answer}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </motion.section>
      </div>
    </Layout>
  );
}
