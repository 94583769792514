import React, { useState } from "react";

export default function ImageSection() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="relative w-full h-screen overflow-hidden z-0" // Fullscreen and lower z-index
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Background Video */}
      <video
        src="/video/video.mp4" // Update with your actual video path
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover"
      ></video>

      {/* Overlay effect on hover */}
      {isHovered && (
        <div className="absolute inset-0 bg-white opacity-75 transition-opacity"></div>
      )}

      {/* Optional Content Layer */}
      <div className="relative z-10 flex items-center justify-center h-full"></div>
    </div>
  );
}
