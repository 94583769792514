import React, { useEffect } from "react";
import Header from "../components/Header/page";
import FooterPage from "../components/Footer/page";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  children: React.ReactNode;
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <ScrollToTop />
      <Header />
      <main className="flex-grow">{children}</main>
      <FooterPage />
    </div>
  );
};

export default Layout;
