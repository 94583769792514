import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  MapPin,
  ChevronDown,
  Users,
  Globe,
  Building2,
  Cog,
  Bot,
  Music,
  Video,
  Presentation,
  GraduationCap,
  Factory,
  Utensils,
  Mail,
  User,
} from "lucide-react";
import { Card, CardContent } from "../../components/ui/Card";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/Dialog";
import { Button } from "../../components/ui/Button";
import Layout from "../layout";
import { Input } from "../../components/ui/Input";
import { Label } from "../../components/ui/Label";
import { Img } from "../../components/ui/Img";

interface Activity {
  day: number | string;
  title: string;
  description: string;
  icon: React.ReactNode;
  youtubeLinks: string[];
}

interface RoadTripStageData {
  id: number;
  location: string;
  title: string;
  days: string;
  description: string;
  activities: Activity[];
  image: string;
  color: string;
  images: string[];
}

const roadTripStages: RoadTripStageData[] = [
  {
    id: 1,
    location: "Cultural Immersion",
    title: "Welcome & Cultural Experience",
    days: "1-5",
    description:
      "Begin your journey with Korean culture, language, and industry insights.",
    activities: [
      {
        day: "1-2",
        title: "Welcome, Orientation, and Korean Culture Overview",
        description: ` Welcome and introduction to the program
            Overview of Korean culture.
            Team-building activities to foster connections.`,
        icon: <Globe className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/96zu5cAo6Rc?si=O-n7EVqy9VhqdWj9",
          "https://www.youtube.com/embed/Naezik_Qv4A?si=WDyJvqrMiqVhoTFj",
        ],
      },
      {
        day: "3",
        title: "Korean Culinary Experience",
        description:
          "Cooking class to make Korean dishes like kimchi and tokbokki",
        icon: <Utensils className="h-5 w-5" />,
        youtubeLinks: [
          "https://youtube.com/embed/Zf0K6dYFbx0?si=Aor_ZPtFLXjDEk2a",
        ],
      },
      {
        day: "4",
        title: "Traditional Village Visit",
        description:
          "Explore a Vietnamese Lu Dynasty Clan Village and participate in  Amusement park ",
        icon: <Building2 className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/ajXdiyIIyi8?si=nUvoEbgVtPs3MJM2",
          "https://www.youtube.com/embed/4hzMw8yL5Z4?si=4AQxCKeLIpQB3vQ9",
        ],
      },
      {
        day: "5",
        title: "Industry Excursion",
        description:
          "Tour Hyundai's facilities to learn about automotive innovations",
        icon: <Factory className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/g-zhYIo9K5Q?si=LQWqaHyFOyZFmqnS",
        ],
      },
    ],
    image: "Images/Camping/section1/section1-1.jpg",
    color: "from-[#FFB6C1] to-[#FFD700]",
    images: [
      "Images/Camping/section1/section1-1.jpg",
      "Images/Camping/section1/section1-2.jpg",
      "Images/Camping/section1/section1-3.jpg",
    ],
  },
  {
    id: 2,
    location: "STEAM & Technology",
    title: "Innovation & Engineering",
    days: "5-7",
    description:
      "Dive into STEAM, AI, and Robotics with hands-on workshops and industry visits.",
    activities: [
      {
        day: "5",
        title: "STEM Workshop",
        description:
          "Introduction to engineering principles with hands-on challenges",
        icon: <Cog className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/F7FkTfjfm5s?si=XGAgdePsyKUdvOiC",
        ],
      },
      {
        day: "6",
        title: "AI & Robotics",
        description: "Learn AI basics and build a simple robot using coding",
        icon: <Bot className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/F7FkTfjfm5s?si=XGAgdePsyKUdvOiC",
        ],
      },
      {
        day: "7",
        title: "Industry Visit - Apple Developer Academy @POSTECH ",
        description:
          "Tour of Apple Developer Academy @POSTECH to understand industrial innovation",
        icon: <Factory className="h-5 w-5" />,
        youtubeLinks: [
          "https://youtube.com/embed/-DyAz1_phqc?si=aFuOEBJeZK36DnKn",
        ],
      },
    ],
    image: "Images/Camping/section2/section2-1.jpg",
    color: "from-[#87CEEB] to-[#00CED1]",
    images: [
      "Images/Camping/section2/section2-1.jpg",
      "Images/Camping/section2/section2-2.jpg",
      "Images/Camping/section2/section2-3.jpg",
    ],
  },
  {
    id: 3,
    location: "K-pop & Performance",
    title: "Creative Expression",
    days: "8-9",
    description:
      "Express yourself through K-pop dance, vocal training, and video production.",
    activities: [
      {
        day: "8-9",
        title: "K-pop Workshop and Music Video Project",
        description: `Learn K-pop choreography and vocal techniques with professional instructors
          Film a group music video to showcase skills developed during the week`,
        icon: <Music className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/PEPLtn5RRB8?si=DVvEVAhbVqy3peyl",
        ],
      },
    ],
    image: "Images/Camping/section3/section3-1.jpg",
    color: "from-[#FF69B4] to-[#FF1493]",
    images: [
      "Images/Camping/section3/section3-1.jpg",
      "Images/Camping/section3/section3-2.jpg",
      "Images/Camping/section3/section3-3.jpg",
    ],
  },
  {
    id: 4,
    location: "Final Showcase",
    title: "Celebration & Awards and Awards Ceremony",
    days: "10",
    description: "Present your achievements and celebrate your journey.",
    activities: [
      {
        day: "10",
        title: "Final Presentation",
        description:
          "Team presentations of projects from AI, K-pop, and language sessions",
        icon: <Presentation className="h-5 w-5" />,
        youtubeLinks: [
          "https://www.youtube.com/embed/wV3pkHbk4ZQ?si=l-ac5mXaZU8gBc8f",
        ],
      },
    ],
    image: "Images/Camping/section4/section4-1.jpg",
    color: "from-[#FFD700] to-[#FFA500]",
    images: [
      "Images/Camping/section4/section4-1.jpg",
      "Images/Camping/section4/section4-2.jpg",
      "Images/Camping/section4/section4-3.jpg",
    ],
  },
];

const RoadTripStage = React.forwardRef<
  HTMLDivElement,
  {
    stage: RoadTripStageData;
    isLast: boolean;
    isActive: boolean;
    onExpand: () => void;
  }
>(({ stage, isLast, isActive, onExpand }, ref) => {
  return (
    <motion.div
      ref={ref}
      className={`mb-16 ${isLast ? "" : "pb-16"} cursor-pointer`}
      initial={{ x: -50, opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      onClick={onExpand}
    >
      <Card
        className={`transition-all duration-300 overflow-hidden mb-8 relative ${
          isActive ? "shadow-lg border-[#FF9999] border-2" : "hover:shadow-md"
        }`}
      >
        <CardContent className="p-0">
          <motion.div
            className={`relative h-48 bg-gradient-to-r ${stage.color}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Img
              src={stage.image}
              alt={stage.location}
              className="w-full h-full object-cover mix-blend-overlay"
            />
            <motion.div
              className="absolute top-4 left-4 bg-white-a700 rounded-full p-2"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                delay: 0.2,
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            >
              <MapPin className="text-[#FF9999]" />
            </motion.div>
            <motion.div
              className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              <h3 className="text-2xl font-bold text-white-a700">
                {stage.location}
              </h3>
              <p className="text-[#FFD700]">Days {stage.days}</p>
            </motion.div>
          </motion.div>
          <div className="p-6 bg-white-a700">
            <motion.h4
              className="text-xl font-semibold text-[#FF9999] mb-2"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              {stage.title}
            </motion.h4>
            <motion.p
              className="text-gray-600 mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              {stage.description}
            </motion.p>
            <AnimatePresence>
              {isActive && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="overflow-hidden"
                >
                  <h5 className="font-semibold text-[#87CEEB] mb-2">
                    Activities:
                  </h5>
                  <ul className="space-y-4">
                    {stage.activities.map((activity, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1 }}
                      >
                        <Dialog>
                          <DialogTrigger asChild>
                            <div
                              className="flex items-center space-x-2 p-4 rounded-lg hover:bg-[#FFB6C1]/10 cursor-pointer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="bg-[#FFB6C1]/20 rounded-full p-2">
                                {activity.icon}
                              </div>
                              <div>
                                <p className="font-medium text-[#FF9999]">
                                  Day {activity.day}: {activity.title}
                                </p>
                                <p className="text-sm text-gray-600">
                                  {activity.description}
                                </p>
                              </div>
                            </div>
                          </DialogTrigger>
                          <DialogContent
                            className="sm:max-w-[800px] bg-white-a700 rounded-lg shadow-lg p-6"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div
                              className={`${
                                activity.youtubeLinks.length === 1
                                  ? "grid place-items-center"
                                  : "grid grid-cols-2 gap-4"
                              }`}
                            >
                              {activity.youtubeLinks.map((link, index) => (
                                <iframe
                                  width="350"
                                  height="300"
                                  key={index}
                                  src={link}
                                  // Ensure the iframe takes up the available width and scales responsively
                                  title={`YouTube video ${index + 1}`}
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              ))}
                            </div>
                            <div className="mt-4">
                              <h3 className="text-xl font-bold text-[#FF9999]">
                                Day {activity.day}: {activity.title}
                              </h3>
                              <p
                                className="text-gray-600"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {activity.description}
                              </p>
                            </div>
                          </DialogContent>
                        </Dialog>
                      </motion.li>
                    ))}
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="mt-4"
            >
              <h5 className="font-semibold text-[#87CEEB] mb-2">
                Stage Highlights:
              </h5>
              <div className="grid grid-cols-3 gap-2">
                {stage.images.map((image, index) => (
                  <Img
                    key={index}
                    src={image}
                    alt={`${stage.location} - Image ${index + 1}`}
                    className="w-full h-full object-cover rounded-lg"
                  />
                ))}
              </div>
            </motion.div>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
});

const IntroductionSection: React.FC = () => {
  return (
    <section className="relative py-20 overflow-hidden bg-gradient-to-br from-[#FFB6C1]/30 to-[#87CEEB]/30">
      <div className="absolute inset-0 backdrop-blur-xl"></div>
      <div className="relative container mx-auto px-4">
        <div className="max-w-4xl mx-auto bg-white-a700 rounded-3xl p-8 shadow-xl border-2 border-dashed border-gray-300">
          <h2 className="text-4xl font-bold mb-6 text-[#FF9999]">
            Introduction
          </h2>
          <p className="text-lg text-gray-700 leading-relaxed">
            Our Korea Summer Camp 2025 offers an immersive experience into
            Korean culture, language, and cutting-edge technology. Tailored for
            ages 10-17, our program combines cultural exploration, K-pop
            workshops, STEAM & AI robotics, and exclusive industry excursions.
          </p>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
            <Card className="bg-[#FFB6C1]/10">
              <CardContent className="p-6">
                <Globe className="w-8 h-8 text-[#FF9999] mb-4" />
                <h3 className="text-xl font-semibold mb-2">
                  Cultural Immersion
                </h3>
                <p className="text-gray-600">
                  Experience authentic Korean culture and language
                </p>
              </CardContent>
            </Card>
            <Card className="bg-[#87CEEB]/10">
              <CardContent className="p-6">
                <Bot className="w-8 h-8 text-[#87CEEB] mb-4" />
                <h3 className="text-xl font-semibold mb-2">Tech Innovation</h3>
                <p className="text-gray-600">Learn STEAM, AI, and robotics</p>
              </CardContent>
            </Card>
            <Card className="bg-[#FFB6C1]/10">
              <CardContent className="p-6">
                <Music className="w-8 h-8 text-[#FF9999] mb-4" />
                <h3 className="text-xl font-semibold mb-2">K-pop Experience</h3>
                <p className="text-gray-600">
                  Dance, sing, and create music videos
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

const RegistrationForm: React.FC = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-[#87CEEB]/10 to-[#FF9999]/10">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold mb-8 text-center text-[#FF9999]">
            Register Your Interest
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Card className="bg-white shadow-lg">
              <CardContent className="p-6">
                <h3 className="text-2xl font-semibold mb-4 text-[#87CEEB]">
                  Company Information
                </h3>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Mail className="text-[#FF9999]" />
                    <span>Tax Code: 0316677870</span>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Mail className="text-[#FF9999]" />
                    <span>Bank Account Number: 700018596440</span>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="bg-white shadow-lg">
              <CardContent className="p-6">
                <form className="space-y-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <Label htmlFor="firstName">First Name</Label>
                      <Input id="firstName" placeholder="Enter first name" />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="lastName">Last Name</Label>
                      <Input id="lastName" placeholder="Enter last name" />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="email">Email</Label>
                    <Input
                      id="email"
                      type="email"
                      placeholder="Enter email address"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="phone">Phone Number</Label>
                    <Input
                      id="phone"
                      type="tel"
                      placeholder="Enter phone number"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="age">Age of Participant</Label>
                    <Input
                      id="age"
                      type="number"
                      min="10"
                      max="17"
                      placeholder="Enter age"
                    />
                  </div>
                  <Button
                    className="w-full bg-gradient-to-r from-[#FF9999] to-[#87CEEB] hover:from-[#FF8888] hover:to-[#7BBED9] text-white-a700"
                    size="lg"
                  >
                    Submit Registration
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

const SummerCamp: React.FC = () => {
  const [activeStage, setActiveStage] = useState(1);
  const stageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [expandedStage, setExpandedStage] = useState<number | null>(null);

  useEffect(() => {
    stageRefs.current = stageRefs.current.slice(0, roadTripStages.length);
  }, []);

  const scrollToStage = (index: number) => {
    const element = stageRefs.current[index];
    if (element) {
      const yOffset = -100;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (let i = stageRefs.current.length - 1; i >= 0; i--) {
        const element = stageRefs.current[i];
        if (element && element.offsetTop <= scrollPosition) {
          setActiveStage(i + 1);
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Layout>
      <div className="min-h-screen ">
        {/* Hero Section */}
        <section className="relative min-h-screen overflow-hidden bg-gradient-to-br from-[#FFB6C1]/50 to-[#87CEEB]/50 pt-20">
          <div className="absolute inset-0 backdrop-blur-3xl"></div>
          <div className="relative container mx-auto px-4 py-20">
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-gray-500 tracking-wider text-sm mb-20"
            >
              SUMMER CAMP 2025
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="space-y-4 mb-40"
            >
              <h1 className="text-4xl md:text-6xl lg:text-7xl text-red-600 font-bold tracking-wider leading-tight font-marker">
                Experience Korea
                <br />
                Like Never Before!
                <motion.span
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, delay: 0.8 }}
                  className="inline-block ml-2"
                >
                  ↗
                </motion.span>
              </h1>

              <p className="text-gray-500 text-sm">JUNE - JULY 2025</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 60 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.4 }}
              className="space-y-6"
            >
              <p className="text-xl md:text-2xl text-gray-500 font-medium tracking-wide">
                Language, Culture, K-pop, and Tech
              </p>
              <h2 className="text-3xl md:text-5xl lg:text-6xl font-bold tracking-wider font-marker">
                <span className="text-[#fd5b5b]">All in One </span>
                <span className="text-[#53bae2]">Summer Camp</span>
              </h2>
            </motion.div>
          </div>
        </section>

        {/* Introduction Section */}
        <IntroductionSection />

        {/* Navigation */}
        <header className="bg-gradient-to-r from-[#FF9999] to-[#87CEEB] hover:from-[#FF8888] hover:to-[#7BBED9] text-white-a700 p-4 sticky top-[85px] z-10">
          <div className="container mx-auto flex flex-col sm:flex-row md:flex-col lg:flex-row justify-between items-center">
            <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-0 md:mb-4 lg:mb-0 text-white-a700">
              Adventure Camp Road Trip 2025
            </h1>
            <nav className="w-full sm:w-auto md:w-full lg:w-auto">
              <ul className="flex space-x-2 flex-wrap justify-center sm:justify-start md:justify-center lg:justify-start -mx-1 sm:mx-0">
                {roadTripStages.map((stage, index) => (
                  <li
                    key={stage.id}
                    className="px-1 sm:px-0 mb-2 w-1/2 sm:w-auto md:w-1/3 lg:w-auto"
                  >
                    <Button
                      variant="outline"
                      size="sm"
                      className="w-full text-white-a700 border-white-a700 hover:bg-[#FF8888]"
                      onClick={() => scrollToStage(index)}
                    >
                      {stage.location}
                    </Button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </header>

        {/* Main Section */}
        <main className="container mx-auto px-4 py-8 bg-white-a700">
          {/* Section: Introduction */}
          <section className="text-center mb-24">
            <h2 className="text-6xl font-bold text-[#FF9999] mb-4">
              Your Adventure Awaits
            </h2>
            <p className="text-xl text-gray-700 mb-6">
              Embark on a journey through culture, technology, and creativity in
              this unique camping adventure!
            </p>
            <Button
              size="lg"
              className="bg-[#87CEEB] text-white-a700 hover:bg-[#5F9EA0] px-8 py-4 text-lg rounded-full shadow-lg"
              onClick={() => scrollToStage(0)}
            >
              Start Your Journey
              <ChevronDown className="ml-2 h-5 w-5" />
            </Button>
          </section>

          {/* Timeline and Stages */}
          <div className="relative flex">
            {/* Timeline */}

            <div className="absolute left-0 sm:left-4 md:left-6 top-0 bottom-0 w-1 sm:w-1.5 md:w-2 bg-[#FFB6C1]/30 rounded-full overflow-hidden">
              {roadTripStages.map((stage, index) => (
                <motion.div
                  key={stage.id}
                  className={`absolute w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 -left-1.5 sm:-left-1.75 md:-left-2 rounded-full border-2 border-white ${
                    index < activeStage - 1 ? "bg-[#FF9999]" : "bg-[#FFB6C1]/30"
                  }`}
                  style={{
                    top: `${(index / (roadTripStages.length - 1)) * 100}%`,
                    transform: "translateY(-50%)",
                  }}
                />
              ))}
            </div>

            {/* Stages */}
            <div className="ml-8 flex-1">
              {roadTripStages.map((stage, index) => (
                <RoadTripStage
                  key={stage.id}
                  ref={(el: HTMLDivElement | null) =>
                    (stageRefs.current[index] = el)
                  }
                  stage={stage}
                  isActive={expandedStage === stage.id}
                  onExpand={() =>
                    setExpandedStage((prev) =>
                      prev === stage.id ? null : stage.id
                    )
                  }
                  isLast={index === roadTripStages.length - 1}
                />
              ))}
            </div>
          </div>
        </main>

        {/* Registration Form */}
        <RegistrationForm />
      </div>
    </Layout>
  );
};

export default SummerCamp;
