import React from "react";
import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";
import { Img } from "../ui/Img";
import Layout from "../../pages/layout";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Layout>
      <div className="min-h-screen flex flex-col items-center justify-center bg-white">
        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
            className="mb-6"
          >
            <Img src="/Images/not-found.png" className="w-16 h-16 mx-auto" />
          </motion.div>

          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-2xl font-bold mb-4 text-gray-900"
          >
            Page Not Found
          </motion.h1>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <Button
              onClick={handleGoHome}
              className="bg-blue-500 text-white-a700 hover:bg-blue-600"
            >
              Go Home
            </Button>
          </motion.div>
        </motion.div>
      </div>
    </Layout>
  );
};

export default NotFound;
