import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import { Img } from "../../components/ui/Img";
import Layout from "../layout";

interface SectionProps {
  title: string;
  description: string;
  imageSrc: string;
  backgroundSrc: string;
  isHovered: boolean;
  onHover: () => void;
  onLeave: () => void;
  glowColor: string;
  onClick: () => void; // Add onClick prop for navigation
}

export default function ProductsPage() {
  const [hoveredSection, setHoveredSection] = useState<
    "robotics" | "drones" | null
  >(null);

  const navigate = useNavigate(); // Initialize navigate

  return (
    <Layout>
      <div className="flex flex-col md:flex-row min-h-screen overflow-hidden">
        <Section
          title="Robotic AI"
          description="Cutting-edge artificial intelligence for advanced robotics"
          imageSrc="/placeholder.svg" // replace with actual image path
          backgroundSrc="/placeholder.svg" // replace with actual background path
          isHovered={hoveredSection === "robotics"}
          onHover={() => setHoveredSection("robotics")}
          onLeave={() => setHoveredSection(null)}
          glowColor="from-blue-400 to-blue-500"
          onClick={() => navigate("/products/robotics")} // Navigate to AI page
        />
        <Section
          title="Drones"
          description="High-performance drones for various applications"
          imageSrc="/placeholder.svg" // replace with actual image path
          backgroundSrc="/placeholder.svg" // replace with actual background path
          isHovered={hoveredSection === "drones"}
          onHover={() => setHoveredSection("drones")}
          onLeave={() => setHoveredSection(null)}
          glowColor="from-blue-400 to-blue-500"
          onClick={() => navigate("/products/drones")} // Navigate to drones page
        />
      </div>
    </Layout>
  );
}

const Section: React.FC<SectionProps> = ({
  title,
  description,
  imageSrc,
  backgroundSrc,
  isHovered,
  onHover,
  onLeave,
  glowColor,
  onClick, // Receive onClick prop
}) => {
  return (
    <motion.div
      className="relative flex-1 flex flex-col items-center justify-center p-8 overflow-hidden bg-black/40 cursor-pointer"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      onClick={onClick} // Trigger navigation on click
      style={{ minHeight: "100vh" }}
    >
      {/* Background image */}
      <Img
        src={backgroundSrc}
        alt={`${title} background`}
        className="absolute inset-0 w-full h-full object-cover opacity-60"
        style={{ filter: "blur(8px)" }}
      />
      {/* Overlay */}
      <div className="absolute inset-0 bg-black/20" />
      {/* Glow effect */}
      <motion.div
        className={`absolute inset-0 bg-gradient-to-br ${glowColor} opacity-0`}
        animate={{ opacity: isHovered ? 0.3 : 0 }}
        transition={{ duration: 0.3 }}
      />
      {/* Content */}
      <motion.div
        className="relative z-10 text-center flex flex-col items-center w-full max-w-lg mx-auto"
        animate={{ scale: isHovered ? 1.05 : 1 }}
        transition={{ duration: 0.3 }}
      >
        <h2
          className={`text-4xl font-bold mb-4 ${
            isHovered ? "text-white-a700" : "text-black-900_14"
          } `}
          style={{
            textShadow: isHovered
              ? "0 0 8px rgba(255, 255, 255, 0.7), 0 0 15px rgba(255, 255, 255, 0.7)"
              : "none",
          }}
        >
          {title}
        </h2>
        <p
          className={`text-lg mb-8 text-gray-300 max-w-md ${
            isHovered ? "text-white-a700" : "text-black-900_14"
          }`}
          style={{
            textShadow: isHovered
              ? "0 0 8px rgba(255, 255, 255, 0.7), 0 0 15px rgba(255, 255, 255, 0.7)"
              : "none",
          }}
        >
          {description}
        </p>
        <div className="relative w-64 h-64 mb-8">
          <Img
            src={imageSrc}
            alt={title}
            className="rounded-lg shadow-lg object-cover w-full h-full"
          />
        </div>
        <motion.button
          className="px-6 py-3 bg-white text-black rounded-full font-semibold hover:bg-opacity-90 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Learn More
        </motion.button>
      </motion.div>
    </motion.div>
  );
};
