import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  Cpu,
  Battery,
  Zap,
  Star,
  ArrowBigLeftDash,
  Filter,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/ui/Card";
import { Button } from "../../../components/ui/Button";
import { Slider } from "../../../components/ui/Slider";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../components/ui/Sheet";
import Layout from "../../layout";

const products = [
  {
    id: 1,
    name: "AI-Powered Drone",
    price: 599,
    category: "Aerial",
    description: "Autonomous flight with advanced object recognition.",
    rating: 4.7,
    icon: Zap,
    image: "/Images/ai-powered-drone.jpg",
  },
  {
    id: 2,
    name: "Smart Home Robot",
    price: 399,
    category: "Home",
    description: "Your personal assistant for household tasks.",
    rating: 4.5,
    icon: Cpu,
    image: "/Images/smart-home-robot.jpg",
  },
  {
    id: 3,
    name: "Industrial Robotic Arm",
    price: 1299,
    category: "Industrial",
    description: "Precision control for manufacturing processes.",
    rating: 4.8,
    icon: Battery,
    image: "/Images/industrial-robotic-arm.jpg",
  },
  {
    id: 4,
    name: "Educational Robot Kit",
    price: 149,
    category: "Education",
    description: "Learn robotics and coding with hands-on projects.",
    rating: 4.6,
    icon: Cpu,
    image: "/Images/educational-robot-kit.jpg",
  },
  {
    id: 5,
    name: "Autonomous Lawn Mower",
    price: 799,
    category: "Home",
    description: "Keep your lawn perfect with AI-driven precision.",
    rating: 4.4,
    icon: Zap,
    image: "/Images/autonomous-lawn-mower.jpg",
  },
  {
    id: 6,
    name: "Robotic Pet Companion",
    price: 299,
    category: "Companion",
    description: "An AI friend that learns and adapts to you.",
    rating: 4.3,
    icon: Battery,
    image: "/Images/robotic-pet-companion.jpg",
  },
];

const categories = [
  "All",
  "Aerial",
  "Home",
  "Industrial",
  "Education",
  "Companion",
];

const DEFAULT_PRICE_RANGE = [0, 1500];
const DEFAULT_CATEGORY = "All";

const RoboticsAIProductPage: React.FC = () => {
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [activeCategory, setActiveCategory] = useState(DEFAULT_CATEGORY);
  const [priceRange, setPriceRange] = useState(DEFAULT_PRICE_RANGE);
  const navigate = useNavigate();

  // Filter Logic
  useEffect(() => {
    const filtered = products.filter(
      (product) =>
        (activeCategory === "All" || product.category === activeCategory) &&
        product.price >= priceRange[0] &&
        product.price <= priceRange[1]
    );
    setFilteredProducts(filtered);
  }, [activeCategory, priceRange]);

  // Reset Filters
  const resetFilters = () => {
    setActiveCategory(DEFAULT_CATEGORY);
    setPriceRange(DEFAULT_PRICE_RANGE);
  };

  // Navigate to product details page
  const handleProductClick = (id: number) => {
    navigate(`/products/robotics/${id}`); // Navigate to the detail page for the product
  };

  // Filter Content Component
  const FilterContent = React.memo(() => (
    <div className="space-y-4 bg-white-a700">
      <div>
        <h3 className="font-semibold mb-2 text-blue-500">Categories</h3>
        <div className="space-y-2">
          {categories.map((category) => (
            <Button
              key={category}
              variant={activeCategory === category ? "default" : "outline"}
              className={`w-full justify-start ${
                activeCategory === category
                  ? "bg-blue-500 text-white-a700"
                  : "text-blue-500 border-blue-400"
              }`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </Button>
          ))}
        </div>
      </div>
      <div>
        <h3 className="font-semibold mb-2 text-blue-500">Price Range</h3>
        <Slider
          min={0}
          max={1500}
          step={1}
          value={priceRange}
          onValueChange={setPriceRange}
          className="mt-2 slider-blue"
        />
        <div className="flex justify-between mt-2 text-blue-500">
          <span>${priceRange[0]}</span>
          <span>${priceRange[1]}</span>
        </div>
      </div>
      <div className="pt-4">
        <Button
          className="w-full bg-red-500 hover:bg-red-600 text-white-a700 hidden sm:block"
          onClick={resetFilters}
        >
          Reset Filters
        </Button>
      </div>
    </div>
  ));

  return (
    <Layout>
      <motion.div
        className="container mx-auto px-4 py-8 bg-white-a700 text-gray-800 min-h-screen mt-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* Back & Filter Buttons in Mobile */}
        <div className="flex flex-row justify-between md:flex-row md:justify-start md:gap-4 gap-2 mb-4">
          {/* Back Button */}
          <Button
            className="md:mr-auto bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded-xl flex items-center justify-center"
            onClick={() => navigate(-1)}
          >
            <ArrowBigLeftDash size={20} className="mr-2" />
            Back
          </Button>

          {/* Filter Button */}
          <Sheet>
            <SheetTrigger asChild>
              <Button className="bg-blue-500 hover:bg-blue-600 text-white-a700 px-4 py-2 rounded-xl md:hidden flex items-center justify-center">
                <Filter size={20} className="mr-2" />
                Filters
              </Button>
            </SheetTrigger>
            <SheetContent side="bottom" className="bg-white-a700 text-gray-800">
              <SheetHeader>
                <SheetTitle className="text-blue-500">Filters</SheetTitle>
              </SheetHeader>
              <div className="mt-4">
                <FilterContent />
              </div>
              {/* Add Apply and Reset Buttons */}
              <div className="flex gap-4 mt-4 px-4">
                <Button
                  className="flex-grow bg-blue-500 hover:bg-blue-600 text-white"
                  onClick={() =>
                    console.log("Apply filters", { activeCategory, priceRange })
                  }
                >
                  Apply Filters
                </Button>
                <Button
                  className="flex-grow bg-red-500 hover:bg-red-600 text-white"
                  onClick={resetFilters}
                >
                  Reset Filters
                </Button>
              </div>
            </SheetContent>
          </Sheet>
        </div>

        {/* Filters */}
        <div className="flex flex-col md:flex-row gap-8 mt-8">
          {/* Desktop Filter */}
          <div className="hidden md:block md:w-1/4">
            <Card className="sticky top-4 bg-white-a700 border-gray-200 shadow">
              <CardHeader>
                <CardTitle className="text-blue-500">Filters</CardTitle>
              </CardHeader>
              <CardContent>
                <FilterContent />
              </CardContent>
            </Card>
          </div>

          {/* Product Section */}
          <motion.div
            className="md:w-3/4 grid grid-cols-1 md:grid-cols-2 gap-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {filteredProducts.map((product) => (
              <motion.div
                key={product.id}
                whileHover={{ scale: 1.05 }}
                className="overflow-hidden bg-white-a700 border border-gray-200 rounded-lg shadow cursor-pointer"
              >
                <Card className="h-full flex flex-col">
                  <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-500 text-white-a700">
                    <div className="flex justify-between items-center">
                      <CardTitle>{product.name}</CardTitle>
                      <product.icon />
                    </div>
                    <CardDescription className="text-blue-100">
                      {product.category}
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="flex-grow">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-full h-48 object-cover rounded-lg mb-4"
                    />
                    <p className="text-gray-600 line-clamp-2">
                      {product.description}
                    </p>
                    <div className="flex items-center mt-2">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <Star
                          key={index}
                          className={`w-4 h-4 ${
                            index < Math.floor(product.rating)
                              ? "text-yellow-500 fill-current"
                              : "text-gray-400"
                          }`}
                        />
                      ))}
                      <span className="ml-2 text-sm text-gray-600">
                        {product.rating.toFixed(1)}
                      </span>
                    </div>
                  </CardContent>
                  <CardFooter className="flex justify-between items-center">
                    <div className="bg-green-500 text-white-a700 px-4 py-2 rounded-full shadow-md">
                      <span className="font-bold text-lg">
                        ${product.price}
                      </span>
                    </div>
                    <Button
                      className="bg-blue-500 hover:bg-blue-600 text-white-a700"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent card click event
                        handleProductClick(product.id);
                      }}
                    >
                      Details
                    </Button>
                  </CardFooter>
                </Card>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.div>
    </Layout>
  );
};

export default RoboticsAIProductPage;
