import React from "react";
const sizes = {
  textxs: "text- [14px] font-normal not-italic",
  texts: "text-[16px] font-normal not-italic",
  textmd: "text-[20px] font-normal not-italic",
  textlg: "text-[24px] font-normal not-italic md: text-[22px]",
  textxl: "text-[34px] font-normal not-italic md: text-[32px] sm:text- [30px]",
};

export type TextProps = Partial<{
  className: string;
  as: any;
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  as,
  size = "texts",
  ...restProps
}) => {
  const Component = as || "p";
  return (
    <Component
      className={`text-gray-300 font-inter ${className} ${sizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};
export { Text };
