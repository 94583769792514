import { useRoutes } from "react-router-dom";
import HomePage from "./pages/LandingPage/Home";
import AboutUsPage from "./pages/AboutUsPage/page";
import ContactUsPage from "./pages/ContactUsPage/page";
import NotFound from "./components/404/page";
import ProductsPage from "./pages/ProductsPage/page";
import RoboticsAIProductPage from "./pages/ProductsPage/RoboticsAI/page";
import DronesProductPage from "./pages/ProductsPage/drones/page";
import CoursePage from "./pages/CoursesPage/page";
import EnhancedProductDetail from "./pages/ProductsPage/drones/details/[id]";
import RoboticsPageDetails from "./pages/ProductsPage/RoboticsAI/details/[id]";
import DronesProductDetails from "./pages/ProductsPage/drones/details/[id]";
import VASRoad from "./pages/Summercamp/page";

const ProjectRoutes = () => {
  let element = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/about",
      element: <AboutUsPage />,
    },
    {
      path: "/contact-us",
      element: <ContactUsPage />,
    },
    {
      path: "/products",
      element: <ProductsPage />,
    },
    {
      path: "/products/robotics",
      element: <RoboticsAIProductPage />,
    },
    {
      path: "/products/robotics/:id", // Dynamic route for Robotics AI product details
      element: <RoboticsPageDetails />,
    },
    {
      path: "/products/drones",
      element: <DronesProductPage />,
    },
    {
      path: "/products/drones/:id", // Dynamic route for Robotics AI product details
      element: <DronesProductDetails />,
    },
    {
      path: "/courses",
      element: <CoursePage />,
    },
    {
      path: "/summer-camp",
      element: <VASRoad />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
