import React from "react";
import { motion } from "framer-motion";
import { Button } from "../../components/ui/Button";
import { Img } from "../../components/ui/Img";
import Layout from "../layout";
import FAQSection from "../FandQSection";
import PartnersSection from "../PartnerSection";

export default function AboutUsPage() {
  const stats = [
    { value: "15K", label: "Happy clients" },
    { value: "100+", label: "Team members" },
    { value: "98%", label: "Customer satisfaction" },
    { value: "100%", label: "Compliance rate" },
  ];

  return (
    <Layout>
      <div className="bg-white">
        {/* Story Section */}
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 py-24"
        >
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="relative h-[400px] rounded-lg overflow-hidden"
            >
              <Img
                src="/Images/LandingPage/AboutUs.jpg?height=400&width=600"
                alt="Team working together"
                className="object-cover"
              />
            </motion.div>
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="bg-white p-6"
            >
              <h1 className="text-3xl font-bold text-[#1A1A1A] mb-4">
                The story behind how our company was founded
              </h1>
              <p className="text-gray-600">
                Vel fermentum mauris elit pellentesque neque porta sed mattis
                aliquam. Praesent nunc urna dictum tellus ac. Placerat sagittis
                elit blandit ultricies placerat. Maecenas sagittis vel sit
                tortor is et te.
              </p>
            </motion.div>
          </div>
        </motion.section>

        {/* Stats Section */}
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="bg-gray-100 py-24"
        >
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
              {stats.map((stat, index) => (
                <motion.div
                  key={stat.label}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="text-center p-6 bg-gray-50 rounded-lg"
                >
                  <div className="text-2xl font-bold text-[#1A1A1A] mb-2">
                    {stat.value}
                  </div>
                  <div className="text-sm text-gray-600">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.section>

        {/* Mission and Vision Section */}
        <motion.section
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="container mx-auto px-4 py-24 relative"
        >
          <div className="flex flex-col gap-16">
            {/* Vision Section */}
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="flex flex-col md:flex-row items-start gap-8"
            >
              <div className="relative mb-8 md:mb-0">
                <div className="w-32 h-32 bg-white rounded-full flex items-center justify-center shadow-sm">
                  <Img
                    src="/placeholder.svg?height=40&width=40"
                    alt="Vision icon"
                    width={40}
                    height={40}
                    className="object-contain"
                  />
                </div>
                <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap">
                  <h2 className="text-lg font-medium">Our vision</h2>
                </div>
              </div>
              <div className="flex-1 mt-4">
                <p className="text-gray-600 mb-4">
                  At ProBalance, our vision is to be the premier accounting firm
                  recognized for transforming financial challenges into
                  opportunities for growth and innovation.
                </p>
                <p className="text-gray-600">
                  Our ultimate goal is to redefine the role of accounting in
                  business success, making financial management a seamless and
                  strategic component of every organization we serve.
                </p>
              </div>
            </motion.div>

            {/* Divider */}
            <div className="w-full h-px bg-gray-200"></div>

            {/* Mission Section */}
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="flex flex-col md:flex-row items-start gap-8"
            >
              <div className="flex-1 order-2 md:order-1">
                <p className="text-gray-600 mb-4">
                  At ProBalance, our mission is to empower businesses of all
                  sizes to achieve financial clarity and success through expert
                  accounting services and strategic financial guidance. We are
                  dedicated.
                </p>
                <p className="text-gray-600">
                  We are committed to being a trusted partner in our clients'
                  success, guiding them towards their financial goals with
                  professionalism, integrity and unwavering dedication.
                </p>
              </div>
              <div className="relative order-1 md:order-2 mb-8 md:mb-0">
                <div className="w-32 h-32 bg-white rounded-full flex items-center justify-center shadow-sm">
                  <Img
                    src="/placeholder.svg?height=40&width=40"
                    alt="Mission icon"
                    width={40}
                    height={40}
                    className="object-contain"
                  />
                </div>
                <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap">
                  <h2 className="text-lg font-medium">Our Mission</h2>
                </div>
              </div>
            </motion.div>

            {/* Divider */}
            <div className="w-full h-px bg-gray-200"></div>
            {/* core value */}

            <motion.section
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="flex flex-col md:flex-row items-start gap-8"
            >
              <div className="flex flex-col gap-8">
                <motion.div
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                  className="flex flex-col md:flex-row items-start gap-8"
                >
                  <div className="relative mb-8 md:mb-0">
                    <div className="w-32 h-32 bg-white rounded-full flex items-center justify-center shadow-sm">
                      <Img
                        src="/placeholder.svg?height=40&width=40"
                        alt="Core Values icon"
                        width={40}
                        height={40}
                        className="object-contain"
                      />
                    </div>
                    <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap">
                      <h2 className="text-lg font-medium">Our Core Values</h2>
                    </div>
                  </div>
                  <div className="flex-1 mt-4">
                    <p className="text-gray-600 mb-4">
                      We believe in integrity, collaboration, and commitment to
                      excellence. Our core values drive every decision, ensuring
                      we uphold the highest standards in everything we do.
                    </p>
                    <p className="text-gray-600">
                      At ProBalance, we are dedicated to empowering our clients
                      and nurturing a culture of growth, respect, and
                      transparency.
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.section>
          </div>
        </motion.section>

        {/* Partners Section */}
        <PartnersSection />

        {/* CTA Section */}
        <motion.section
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="bg-[#0066CC] text-white-a700 py-24 relative overflow-hidden"
        >
          <div className="container mx-auto px-4 text-center relative z-10">
            <div className="max-w-2xl mx-auto">
              <motion.h2
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="text-2xl font-bold mb-4"
              >
                Ready to transform your finances?
              </motion.h2>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="mb-6 text-gray-200"
              >
                Ut lacus et sollicitudin amet nunc tristique nulla ultrices sit.
                Aliquet tempor sed turpis vitae tristique turpis non fermentum.
              </motion.p>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <Button
                  variant="secondary"
                  size="lg"
                  className="bg-[#FFD700] hover:bg-[#FFD700]/90 text-black"
                >
                  Get Started
                </Button>
              </motion.div>
            </div>
          </div>
          {/* Vertical lines */}
          <div className="absolute inset-0">
            {[...Array(6)].map((_, i) => (
              <motion.div
                key={i}
                initial={{ height: 0 }}
                animate={{ height: "100%" }}
                transition={{ duration: 1, delay: i * 0.1 }}
                className="absolute w-px bg-blue-400/20"
                style={{ left: `${(100 / 5) * i}%` }}
              />
            ))}
          </div>
        </motion.section>

        <FAQSection />
      </div>
    </Layout>
  );
}
