import { motion, useInView } from "framer-motion";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/Accordion";
import { useRef } from "react";

const FAQSection: React.FC = () => {
  const faqRef = useRef(null);
  const faqInView = useInView(faqRef, { once: true });

  return (
    <motion.section
      className="py-20"
      ref={faqRef}
      initial={{ opacity: 0 }}
      animate={faqInView ? { opacity: 1 } : {}}
      transition={{ duration: 2 }}
    >
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-center font-bold mb-4 text-gray-900 text-4xl"
          initial={{ opacity: 0, y: -20 }}
          animate={faqInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
        >
          Frequently Asked Questions
        </motion.h2>
        <motion.p
          className="text-center text-gray-500 mb-12"
          initial={{ opacity: 0 }}
          animate={faqInView ? { opacity: 1 } : {}}
          transition={{ duration: 1.6, delay: 1.2 }}
        >
          Risus vitae penatibus consequat pellentesque consequat tempor.
        </motion.p>
        <div className="max-w-3xl mx-auto">
          <Accordion type="single" collapsible>
            {[
              {
                question: "What services are included in the Basic Plan?",
                answer:
                  "Rorem ipsum dolor sit amet consectetur. Sit est nunc proin tellus posuere egestas vitae morbi. Sed molestie nam ut at non facilisis fames nunc ut.",
              },
              {
                question: "Can I upgrade my plan as my business grows?",
                answer:
                  "Yes, you can upgrade your plan at any time to suit your growing business needs.",
              },
              {
                question: "How does the custom plan work?",
                answer:
                  "Our custom plan is tailored to your unique requirements. Contact us for a consultation.",
              },
              {
                question: "How do I get started with your services?",
                answer:
                  "Getting started is easy! Simply contact our team, and we'll guide you through the process.",
              },
            ].map((faq, index) => (
              <AccordionItem key={index} value={`item-${index}`}>
                <motion.div
                  className="bg-white-a700 rounded-lg shadow-sm mb-3"
                  initial={{ opacity: 0, y: 20 }}
                  animate={faqInView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 1.5, delay: index * 0.1 }}
                >
                  <AccordionTrigger className="font-medium text-gray-800 px-4 py-3 w-full text-left">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent className="px-4 py-2 text-gray-600">
                    {faq.answer}
                  </AccordionContent>
                </motion.div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </motion.section>
  );
};

export default FAQSection;
